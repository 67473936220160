import React from 'react';
import StyledFollowUs from './FollowUs.styles';
import Subtitle from '../../atoms/Subtitle/Subtitle.jsx';
import SocialsBar from '../../molecules/SocialsBar/SocialsBar.jsx';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const FollowUs = props => {
    let { lang } = useParams();
    const { jsonData } = useSelector(state => state.dataReducer);

    return (
        <StyledFollowUs>
            <Subtitle text={jsonData[lang].site.copy.follow} />
            <SocialsBar />
        </StyledFollowUs>
    );
};

export default FollowUs;
