import styled from 'styled-components';
import cssVars from '../../../styles/cssVars';
import Container from '../../styled/Container';
import bp from '../../../styles/functions/bp';
import Button from '../../atoms/Button/Button.styles';
import {distance, fontSize} from '../../../styles/functions/cssRulesPerBreakpointPresets';
import bgImage from '../../../static/intro_bg.jpg';

const StyledIntro = styled.div`

    height: 100vh;
    background-color: ${cssVars.colors.blueDark};

    ${Container} {
        height: calc(100vh - 70px);
        padding-top: 40px;

        ${bp.lg`
            height: calc(100vh - 250px);
        `};
    }

      &:before {
          content:'';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          background-image: url(${bgImage});
          background-size: cover;
          background-position: center;
      }

    .inner {
        padding-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ${bp.lg`
            padding-top: 120px;
            max-width: 460px;
            margin-left: auto;
            margin-right: auto;
        `};
    }

    .content {
        color: ${cssVars.colors.white};
    }

    .title {
        ${fontSize('h1')};
        ${distance('margin-bottom', 'medium')};
    }

    .intro {
        font-family: 'FedraSerif-Book';
    }

    .seperator {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        display: block;
        height: 1px;
        opacity: 0.15;
        background-color: ${cssVars.colors.blueLighter};
    }

    .description {
        font-family: 'FedraSerif-BookItalic';
    }

     ${Button} {
        width: 100%;
    }
`;

export default StyledIntro;
