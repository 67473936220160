import React from 'react';
import StyledLogo from './Logo.styles';
import { ReactComponent as EmbassyLogo } from '../../../static/svg/embassy_logo.svg';

const Logo = props => (
    <StyledLogo>
         <EmbassyLogo></EmbassyLogo>
    </StyledLogo>
);

export default Logo;
