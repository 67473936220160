import React from "react";
import TourStart from "../components/organisms/TourStart/TourStart";
import Intro from "../components/organisms/Intro/Intro";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Introduction = () => {
    let { lang } = useParams();

    const { jsonData } = useSelector(state => state.dataReducer);

    return jsonData[lang] ? (
        <Intro {...jsonData[lang].site.welcome} lang={lang} />
    ) : (
        <TourStart hideButtons />
    );
};

export default Introduction;
