import styled, { css } from 'styled-components';
import cssVars from '../../../styles/cssVars';
import { fontSize } from '../../../styles/functions/cssRulesPerBreakpointPresets';

const thumb = css`
    height: 12px;
    width: 12px;
    border: none;
    display: block;
    background: ${cssVars.colors.blueLight};
    // cursor: pointer;
`;

const StyledAudioPlayer = styled.div`
    height: 50px;
    width: 100%;
    background-color: ${cssVars.colors.blueDark};
    margin-bottom: 30px;
    display: flex;
    max-width: 420px;

    .controls {
        position: relative;
        min-width: 50px;
        height: 50px;
        background-color: ${cssVars.colors.blueLight};

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .playback {
        padding: 8px 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .time-wrapper {
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;

        .time {
            ${fontSize('extraSmall')};
            color: ${cssVars.colors.white};
            font-family: 'Muli';
            font-weight: 400;
        }
    }

    .progress {
        display: block;
        -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
        width: 100%; /* Specific width is required for Firefox. */
        background: ${cssVars.colors.blueDarker}; /* Otherwise white in Chrome */
        height: 4px;
        padding: 0; // reset

        &:focus {
            outline: 0;
        }

        // default thumb styling
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            ${thumb}
        }

        // firefox
        &::-moz-range-track {
            background-color: ${cssVars.colors.blueDarker};
            border: none;
            outline: none;
        }

        &::-moz-range-thumb {
            ${thumb}
        }

        // microsoft
        &::-ms-track {
            width: 100%;
            cursor: pointer;
            height: 8px !important;

            /* Hides the slider so custom styles can be added */
            background: transparent;
            border-color: transparent;
            color: transparent;
        }

        &::-ms-tooltip {
            display: none;
        }

        &::-ms-fill-lower {
            background: ${cssVars.colors.blueLight};
        }

        &::-ms-fill-upper {
            background: ${cssVars.colors.blueLight};
        }

        &::-ms-thumb {
            ${thumb}
            margin-top: 0;
        }
    }
`;

export default StyledAudioPlayer;
