import styled from 'styled-components';
import { distance, fontSize } from '../../../styles/functions/cssRulesPerBreakpointPresets';

const StyledNoResults = styled.div`
    p {
        ${fontSize('medium')};
        ${distance('padding-bottom', 'large')};
        margin-top: 10px;
        font-family: 'FedraSerif-Medium';
    }
`;

export default StyledNoResults;
