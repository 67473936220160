import React from "react";
import StyledBackButton from "./BackButton.styles";
import { ReactComponent as ChevronIcon } from "../../../static/svg/chevron.svg";
import { Link, useParams } from "react-router-dom";

const BackButton = () => {
    const { lang } = useParams();
    return (
        <StyledBackButton>
            <Link to={`/${lang}/locations`}>
                <ChevronIcon />
            </Link>
        </StyledBackButton>
    );
};
export default BackButton;
