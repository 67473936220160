import styled from "styled-components";
import cssVars from "../../../styles/cssVars";
import { fontSize } from "../../../styles/functions/cssRulesPerBreakpointPresets";

const StyledSearch = styled.div`
    position: relative;

    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .audio {
        svg {
            left: 12px;
            fill: ${cssVars.colors.blueDark};
        }
    }

    input {
        ${fontSize("small")};
        border: none;
        font-family: "Muli";
        font-weight: 400;
        height: 36px;
        padding-left: 40px;
        padding-right: 10px;

        ::-webkit-input-placeholder {
            /* Edge */
            color: ${cssVars.colors.gray};
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: ${cssVars.colors.gray};
        }

        ::placeholder {
            color: ${cssVars.colors.gray};
        }
    }

    .deco {
        position: absolute;
        width: 36px;
        height: 100%;
        top: 0;
        right: 0;
        background-color: ${cssVars.colors.blueLight};

        > button {
            display: block;
            width: 100%;
            height: 100%;
        }

        svg {
            right: 12px;
            fill: ${cssVars.colors.white};
            transform: translate(-50%, -50%);
            left: 50%;
        }
    }
`;

export default StyledSearch;
