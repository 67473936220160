import { combineReducers, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from "redux-persist/lib/storage";
import dataReducer from "./dataReducers";

const persistConfig = {
    key: "root",
    storage
};

const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
        dataReducer
    })
);

const store = createStore(persistedReducer, {}, composeWithDevTools());
const persistor = persistStore(store);

export { store, persistor };
