import styled from 'styled-components';
import cssVars from '../../../styles/cssVars';
import { fontSize } from '../../../styles/functions/cssRulesPerBreakpointPresets';

const StyledNewsletterForm = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;

    form {
      padding: 0; // reset
    }

    .input-wrapper {
        margin-bottom: 10px;
        position: relative;
        width: 300px;
    }

    .input {
        background-color: ${cssVars.colors.grayLight};
        padding-left: 10px;
        border: none;
        width: 100%;
        height: 36px;
        font-family: 'Muli';
        font-weight: 400;

        ::-webkit-input-placeholder { /* Edge */
            color: ${cssVars.colors.gray};
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: ${cssVars.colors.gray};
        }

        ::placeholder {
            color: ${cssVars.colors.gray};
        }
    }

    .submit {
        width: 36px;
        height: 36px;
        background-color: ${cssVars.colors.blueDark};
        position: absolute;
        right: 0;
        top: 0;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }

    .error,
    .succes {
        ${fontSize('small')};
        font-family: 'Muli';
        font-weight: 500;
        font-style: italic;
    }

    .error {
        color: ${cssVars.colors.red};
    }

    .succes {
        color: ${cssVars.colors.grayDark};
    }
`;

export default StyledNewsletterForm;
