export const SET_DATA = "SET_DATA";


const initialState = {
    loading: true,
    jsonData: {
        en: {},
        nl: {},
    },
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_DATA":
            return {
                ...state,
                jsonData: action.payload,
                timestamp: Date.now(),
            };
        default:
            return state;
    }
};

export default dataReducer;
