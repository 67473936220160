import axios from 'axios';

const getAllData = async () => {
    let data = {
        data: {},
        statusCode: 500
    };

    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-data`
        );
        if (response) {
            data = {
                statusCode: response.status,
                data: response.data,
            };
        }
    } catch (error) {
        console.error(error);
        // bugsnagApiError(error, '/get-data', 'getData');
        data.statusCode =
            error.response && error.response.statusCode
                ? error.response.statusCode
                : 500;
    }

    return data;
};

export default getAllData;
