import styled from 'styled-components';
import cssVars from '../../../styles/cssVars';
import { fontSize } from '../../../styles/functions/cssRulesPerBreakpointPresets';

const StyledFooter = styled.div`
    background-color: ${cssVars.colors.blueDark};
    padding-top: 20px;
    padding-bottom: 20px;

    .link,
    span {
        ${fontSize('small')};
        color: ${cssVars.colors.white};
        font-family: 'Muli';
        font-weight: 500;
    }

    span {
        margin-left: 8px;
        margin-right: 8px;
    }
`;

export default StyledFooter;
