import cssVars from '../cssVars';

const breakpointMinMax = (conf = { css: null, min: null, max: null }) => {
    const minQuery = conf.min && typeof conf.min === 'string' && cssVars.breakpoints[conf.min] !== null;
    const maxQuery = conf.max && typeof conf.max === 'string' && cssVars.breakpoints[conf.max] !== null;
    if (minQuery && maxQuery && conf.css) {
        return `@media(min-width: ${cssVars.breakpoints[conf.min]}px) and (max-width:  ${
            cssVars.breakpoints[conf.max]
        }px) {${conf.css}}`;
    }

    if (minQuery && conf.css) {
        if (cssVars.breakpoints[conf.min] > 0) {
            return `@media(min-width: ${cssVars.breakpoints[conf.min]}px) {${conf.css};}`;
        }

        return `${conf.css};`;
    }

    if (maxQuery && conf.css) {
        return `@media(max-width: ${cssVars.breakpoints[conf.max]}px) {${conf.css}}`;
    }

    return null;
};

export default breakpointMinMax;
