import cssRulePerBreakpoint from './cssRulePerBreakpoint';
import cssVars from '../cssVars';

export function column(values) {
    let valueObject = values;

    if (typeof values === 'string') {
        valueObject = cssVars.columnConfigs[values];
    }
    if (valueObject) {
        return cssRulePerBreakpoint('width', valueObject, val => `${100 * val}%`);
    }
    return null;
}

export function order(values) {
    if (values) {
        return cssRulePerBreakpoint('order', values, val => `${val}`);
    }
    return null;
}

export function fontSize(values) {
    let valueObject = values;
    if (typeof values === 'string') {
        // If string given, try to retrieve values from fontConfigs based on key
        valueObject = cssVars.fontConfigs[values];
    }
    if (valueObject) {
        return cssRulePerBreakpoint('font-size', valueObject, val => `${val}px`);
    }
    return null;
}

export function distance(prop = 'margin', values = null, negative = false) {
    let valueObject = values;
    if (typeof values === 'string') {
        valueObject = cssVars.distanceConfigs[values];
    }
    if (valueObject) {
        return cssRulePerBreakpoint(prop, valueObject, val => `${negative ? '-' : ''}${val}px`);
    }
    return null;
}
