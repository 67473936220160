import styled, { css } from "styled-components";
import cssVars from "../../../styles/cssVars";
import { fontSize } from "../../../styles/functions/cssRulesPerBreakpointPresets";

const StyledLanguageToggle = styled.div`
    display: flex;
    align-items: center;

    a {
        ${fontSize("small")};
        display: block;
        font-family: "Muli";
        font-weight: 400;
        color: ${cssVars.colors.white};
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    ${props =>
        props.currentLang === "en"
            ? css`
                  a:first-child {
                      font-weight: 700;
                      pointer-events: none;
                  }
                  a:last-child {
                      font-weight: 500;
                  }
              `
            : css`
                  a:first-child {
                      font-weight: 500;
                  }
                  a:last-child {
                      font-weight: 700;
                      pointer-events: none;
                  }
              `};

    .seperator {
        display: block;
        height: 100%;
        width: 1px;
        background-color: ${cssVars.colors.white};
        margin-left: 10px;
        margin-right: 10px;
    }
`;

export default StyledLanguageToggle;
