import styled from 'styled-components';

const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
    overflow-y: scroll;
`;

export default Overlay;
