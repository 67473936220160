import React from "react";
import StyledNewsletterForm from './NewsletterForm.styles';
import { ReactComponent as ChevronIcon } from '../../../static/svg/chevron.svg';

const NewsletterForm = props => (
    <StyledNewsletterForm>
        <div id="mc_embed_signup">
            <form action="https://embassyofthefreemind.us4.list-manage.com/subscribe/post?u=9b21595eb9368756ee128513e&amp;id=aff63c324a"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_blank"
                    noValidate>
                <div id="mc_embed_signup_scroll" className="input-wrapper">

                    <input type="email" defaultValue="" name="EMAIL" className="input" id="mce-EMAIL" placeholder="email address" required />
                    <button className="submit" type="submit" name="subscribe" id="mc-embedded-subscribe">
                        <ChevronIcon />
                    </button>

                    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                    <div style={{"position": "absolute", "left": "-5000px"}} aria-hidden="true">
                        <input type="text" name="b_9b21595eb9368756ee128513e_aff63c324a" tabIndex="-1" defaultValue="" />
                    </div>
                </div>
            </form>
        </div>
    </StyledNewsletterForm>
);

export default NewsletterForm;
