import styled from 'styled-components';
import SocialsBar from '../../molecules/SocialsBar/SocialsBar.styles.js';

const StyledFollowUs = styled.div`
    width: 100%;

    ${SocialsBar} {
        margin-top: 15px;
        margin-bottom: 15px;
    }
`;

export default StyledFollowUs;
