import styled from "styled-components";
import cssVars from "../../../styles/cssVars";
import { rgba } from "polished";

const StyledBackButton = styled.div`
    a {
        display: block;
        width: 36px;
        height: 36px;
        background-color: ${rgba(cssVars.colors.blueDark, 0.4)};
        position: absolute;
        top: 10px;
        left: 10px;

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
`;

export default StyledBackButton;
