import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import cssVars from '../../styles/cssVars';
import { column, order } from '../../styles/functions/cssRulesPerBreakpointPresets';
import SpaceableFlexObject from './SpaceableFlexObject';

const Box = styled(({ noGutter, grow, ...rest }) => <SpaceableFlexObject {...rest} />)`
    /* Only add paddings if column is given, else only use spacing and flex props */
    ${props =>
        cssVars.grid.gutter &&
        props.column &&
        !props.noGutter &&
        `
        padding-left: ${cssVars.grid.gutter / 2}px;
        padding-right: ${cssVars.grid.gutter / 2}px;
        
        
        
    `};

    flex-grow: ${props => (props.grow ? 1 : 0)};
    flex-wrap: wrap;

    ${props =>
        props.column
            ? column(props.column)
            : css`
                  width: 100%;
              `};

    ${props => (props.order ? order(props.order) : null)};
`;

Box.propTypes = {
    column: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    noGutter: PropTypes.bool,
    grow: PropTypes.bool,
};

Box.defaultProps = {
    noGutter: false,
    grow: false,
};

export default Box;
