import React from "react";
import PropTypes from "prop-types";
import StyledLanguageToggle from "./LanguageToggle.styles";
import { Link, useParams } from "react-router-dom";

const LanguageToggle = () => {
    let { lang } = useParams();

    return (
        <StyledLanguageToggle currentLang={lang}>
            <Link to="/en/locations">EN</Link>
            <span className="seperator"></span>
            <Link to="/nl/locations">NL</Link>
        </StyledLanguageToggle>
    );
};

LanguageToggle.propTypes = {
    currentLang: PropTypes.string.isRequired
};

LanguageToggle.defaultProps = {
    currentLang: PropTypes.string.isRequired
};

export default LanguageToggle;
