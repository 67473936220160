import styled from 'styled-components';
import cssVars from '../../styles/cssVars';
import breakpointMinMax from '../../styles/functions/breakpointMinMax';
import SpaceableFlexObject from './SpaceableFlexObject';
import bpMax from '../../styles/functions/bpMax';

const containerWidth = bp =>
    breakpointMinMax({
        css: `width: ${cssVars.grid.width[bp]}px`,
        min: bp,
    });

const Container = styled(SpaceableFlexObject)`
    ${containerWidth('md')};
    ${containerWidth('lg')};
    ${containerWidth('xl')};

    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    ${bpMax.sm`
        padding-left: 20px;
        padding-right: 20px;
    `};
`;

Container.propTypes = SpaceableFlexObject.propTypes;

export default Container;
