import React from "react";
import PropTypes from 'prop-types';
import StyledHeader from "./Header.styles";
import Search from "../../molecules/Search/Search";
import LanguageToggle from "../../molecules/LanguageToggle/LanguageToggle";
import Container from "../../styled/Container";
import Box from "../../styled/Box";

const Header = ({searchForNumber}) => (
    <StyledHeader>
        <Container>
            <Box>
                <Search searchChanged={searchForNumber}/>
                <LanguageToggle />
            </Box>
        </Container>
    </StyledHeader>
);

Header.propTypes = {
    searchForNumber: PropTypes.func.isRequired,
};

export default Header;
