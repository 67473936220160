import React from 'react';
import StyledSocialsBar from './SocialsBar.styles';
import { ReactComponent as Facebook } from '../../../static/svg/facebook.svg';
import { ReactComponent as Twitter } from '../../../static/svg/twitter.svg';
import { ReactComponent as Instagram } from '../../../static/svg/instagram.svg';
import { ReactComponent as YouTube } from '../../../static/svg/youtube.svg';
import { ReactComponent as Pinterest } from '../../../static/svg/pinterest.svg';

const SocialsBar = props => (
    <StyledSocialsBar>
        <a href="https://www.youtube.com/channel/UCDiIP9YyTduTvsiPMzN0VCg" target="_blank" rel="noopener noreferrer">
            <YouTube />
        </a>
        <a href="https://www.instagram.com/embassyofthefreemind/?hl=en" target="_blank" rel="noopener noreferrer">
            <Instagram />
        </a>
        <a href="https://twitter.com/ritmanlibrary?lang=en" target="_blank" rel="noopener noreferrer">
            <Twitter />
        </a>
        <a href="https://www.facebook.com/embassyofthefreemind" target="_blank" rel="noopener noreferrer">
            <Facebook />
        </a>
        <a href="https://nl.pinterest.com/ritmanlibrary/inside-the-embassy-of-the-free-mind" target="_blank" rel="noopener noreferrer">
            <Pinterest />
        </a>
    </StyledSocialsBar>
);

export default SocialsBar;
