// Converts array with values breakpoint to a key-based object (key = breakpoint)
function convertArrayToResponsiveObject(arr) {
    if (Array.isArray(arr)) {
        return {
            xs: arr[0],
            sm: arr[1],
            md: arr[2],
            lg: arr[3],
            xl: arr[4],
            xxl: arr[5],
        };
    }
    if (typeof arr === 'object') {
        return arr;
    }

    return null;
}

export default convertArrayToResponsiveObject;
