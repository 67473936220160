import styled from 'styled-components';
import bp from '../../../styles/functions/bp';

const StyledPageHeader = styled.div`
    width: 100%;
    height: 270px;
    margin-bottom: 35px;

    ${bp.lg`
        height: 400px;
    `};

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export default StyledPageHeader;
