import styled from 'styled-components';
import cssVars from '../../../styles/cssVars';

const StyledSubtitle = styled.p`
    border-bottom: 1px solid ${cssVars.colors.grayLight};
    color: ${cssVars.colors.blueDark};
    font-family: 'FedraSerif-Medium';
    padding-bottom: 4px;
    margin-bottom: 5px;
`;

export default StyledSubtitle;
