import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { distance } from '../../styles/functions/cssRulesPerBreakpointPresets';
import cssRulePerBreakpoint from '../../styles/functions/cssRulePerBreakpoint';

// Props need to be destructured because otherwise styled-components will pass all of them through to the motion.div
const SpaceableFlexObject = styled(
    ({ textAlign, alignItems, justifyContent, top, bottom, left, right, direction, ...rest }) => <div {...rest} />
)`
    display: flex;
    ${props => props.top && distance('margin-top', props.top)};
    ${props => props.bottom && distance('margin-bottom', props.bottom)};
    ${props => props.left && distance('margin-left', props.left)};
    ${props => props.right && distance('margin-right', props.right)};
    ${props => props.justifyContent && cssRulePerBreakpoint('justify-content', props.justifyContent)};
    ${props => props.alignItems && cssRulePerBreakpoint('align-items', props.alignItems)};
    ${props => props.direction && cssRulePerBreakpoint('flex-direction', props.direction)};
    ${props => props.textAlign && cssRulePerBreakpoint('text-align', props.textAlign)};
`;

SpaceableFlexObject.propTypes = {
    top: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    justifyContent: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
    alignItems: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
    direction: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
    textAlign: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
};

export default SpaceableFlexObject;
