import React from "react";
import PropTypes from "prop-types";
import StyledLocation from "./Location.styles";
import ArtObject from "../../atoms/ArtObject/ArtObject";
import Container from "../../styled/Container";
import Box from "../../styled/Box";
import NoResults from "../NoResults/NoResults";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Location = ({ location, items, range }) => {
    let { lang } = useParams();
    const { jsonData } = useSelector(state => state.dataReducer);

    return (
        <StyledLocation>
            <Container>
                <Box>
                    <div className="inner">
                        <div className="head">
                            <p className="head__location">{location}</p>
                            { range && <p className="head__artworks">{jsonData[lang].site.copy.number} {range}</p> }
                        </div>
                        {items.length > 0 ? (
                            items.map(item => <ArtObject key={`${item.identifier}-${item.title}`} {...item} />)
                        ) : (
                            <NoResults />
                        )}
                    </div>
                </Box>
            </Container>
        </StyledLocation>
    )
};

Location.propTypes = {
    location: PropTypes.string.isRequired,
    range: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(ArtObject.PropTypes))
};

Location.defaultProps = {
    location: "Entrance Hall",
    items: [],
    range: ""
};

export default Location;
