import React from "react";
import PropTypes from "prop-types";
import StyledIntro from "./Intro.styles";
import InnerContentWrapper from "../../styled/InnerContentWrapper";
import Button from "../../atoms/Button/Button.jsx";
import HTMLReactParser from "html-react-parser";

const Intro = ({ title, body, introduction, label_start, lang }) => (
    <StyledIntro>
        <InnerContentWrapper>
            <div className="inner">
                <div className="content">
                    <h1 className="title">{title}</h1>
                    {introduction && HTMLReactParser(introduction)}
                    <div className="seperator" />

                    <div className="description">
                        {body && HTMLReactParser(body)}
                    </div>
                </div>
                <Button text={label_start} url={`/${lang}/locations`} />
            </div>
        </InnerContentWrapper>
    </StyledIntro>
);

Intro.propTypes = {
    lang: PropTypes.oneOf(["nl", "en"]).isRequired,
    title: PropTypes.string.isRequired,
    introduction: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    label_start: PropTypes.string.isRequired
};

export default Intro;
