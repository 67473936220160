import React from 'react';
import PropTypes from "prop-types";
import StyledMeta from './Meta.styles';
import { ReactComponent as AudioIcon } from '../../../static/svg/audio.svg';

const Meta = ({ id, title, year, author }) => (
    <StyledMeta>
        <h1 className="title">
            {title} { year && <span className="year">({year})</span> }
        </h1>
        <div className="content-wrapper">
            <p className="id">
                <AudioIcon />
                <span className="id-text">{id}</span>
            </p>
            <p className="author">{author}</p>
        </div>
    </StyledMeta>
);

Meta.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    year: PropTypes.string,
    author: PropTypes.string.isRequired,
};

Meta.defaultProps = {
    title: 'The Spiritual Brain',
    id: '1',
    year: '1621',
    author: 'Robert Fludd',
};

export default Meta;
