const getFirstAndLastArtwork = (arr) => {
    if(arr.length === 0) return ``;
    if(arr.length === 1) return `${arr[0].identifier}`;
    return `${arr[0].identifier} - ${arr[arr.length - 1].identifier}`;
};

export default (items) => items.map(item => ({
    ...item,
    range: getFirstAndLastArtwork(item.items),
}))

