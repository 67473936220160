import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StyledApp from "./App.styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Start from "./pages/start";
import Locations from "./pages/locations";
import Introduction from "./pages/introduction";
import { SET_DATA } from "./store/dataReducers";
import getAllData from "./api/getAllData";
import Analytics from 'react-router-ga';

const CACHE_TIME_IN_SECONDS = 300; // Interval to re-retrieve data

function App() {
    const { jsonData, timestamp } = useSelector(state => state.dataReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        const getDataAndPushToStore = () => {
            getAllData().then(results => {
                if (results.statusCode === 200 && results.data) {
                    dispatch({ type: SET_DATA, payload: results.data });
                } else {
                    dispatch({ type: SET_DATA, payload: results.data });
                }
            });
        };

        if (jsonData) {
            if (
                !timestamp ||
                (Date.now() - timestamp) / 20 > CACHE_TIME_IN_SECONDS
            ) {
                getDataAndPushToStore();
            }
        } else {
            getDataAndPushToStore();
        }
    }, []);

    return (
        <StyledApp>
            <Router>
                <Analytics id="UA-169205100-1">
                    <Switch>
                        <Route path={["/:lang/locations/:id", "/:lang/locations"]}>
                            <Locations />
                        </Route>
                        <Route path="/:lang/intro">
                            <Introduction />
                        </Route>
                        <Route path="/">
                            <Start />
                        </Route>
                    </Switch>
                </Analytics>
            </Router>
        </StyledApp>
    );
}

export default App;
