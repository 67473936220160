import styled from 'styled-components';
import cssVars from '../../../styles/cssVars';
import bp from '../../../styles/functions/bp';
import { distance, fontSize } from '../../../styles/functions/cssRulesPerBreakpointPresets';
import bgImage from '../../../static/tour_start_bg.jpg';

const StyledTourStart = styled.div`
    background-color: ${cssVars.colors.blueDark};
    height: 100vh;

    .inner {
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        height: calc(100vh - 70px);
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        ${bp.lg`
            height: calc(100vh - 250px);
        `};
    }

      &:before {
          content:'';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          background-image: url(${bgImage});
          background-size: cover;
          background-position: center;
      }

    .tour {
        ${fontSize('small')};
        ${distance('margin-bottom', 'medium')};

        font-family: 'Muli';
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
        color: ${cssVars.colors.white};
    }

    .logo-wrapper {
        margin-top: 20vh;
    }

    .description {
        width: 100%;
    }

    .description-text {
        ${distance('margin-bottom', 'medium')};
        text-align: center;
        font-family: 'FedraSerif-BookItalic';
        color: ${cssVars.colors.white};
    }

    .buttons-wrapper {
        width: 100%;
        display: flex;

        div {
            margin-right: 10px;
            margin-left: 10px;
            flex-grow: 1;
            width: 50%;
        }
    }
`;

export default StyledTourStart;
