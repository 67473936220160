import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import StyledSearch from "./Search.styles";
import { ReactComponent as AudioIcon } from "../../../static/svg/audio.svg";
import { ReactComponent as SearchIcon } from "../../../static/svg/search.svg";
import { ReactComponent as CloseIcon } from "../../../static/svg/close.svg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Search = ({ searchChanged }) => {
    const [searchInput, setSearchInput] = useState('');

    let { lang } = useParams();
    const { jsonData } = useSelector(state => state.dataReducer);

    useEffect(() => {
        if (searchInput) {
            searchChanged(searchInput);
        } else {
            searchChanged(null);
        }
    }, [searchInput]);

    return (
        <StyledSearch>
            <div className="audio">
                <AudioIcon />
            </div>
            <input
                type="text"
                placeholder={jsonData[lang].site.copy.audio_id}
                value={searchInput}
                onChange={e => {
                    setSearchInput(e.target.value);
                }}
            />
            <div className="deco">
                {searchInput ? (
                    <button
                        type="button"
                        onClick={() => {
                            setSearchInput('');
                        }}
                    >
                        <CloseIcon />
                    </button>
                ) : (
                    <SearchIcon />
                )}
            </div>
        </StyledSearch>
    );
};

Search.propTypes = {
    searchChanged: PropTypes.func.isRequired
};

export default Search;
