import { createGlobalStyle } from 'styled-components/macro';
import FedraSerifProBBold from '../static/fonts/FedraSerifProBBold.woff';
import FedraSerifProBBook from '../static/fonts/FedraSerifProBBook.woff';
import FedraSerifProABookItalic from '../static/fonts/FedraSerifProABookItalic.woff';
import FedraSerifProBMedium from '../static/fonts/FedraSerifProBMedium.woff';
import FedraSerifProBNormalIta from '../static/fonts/FedraSerifProBNormalIta.woff';
import FedraSerifProBBold2 from '../static/fonts/FedraSerifProBBold.woff2';
import FedraSerifProBBook2 from '../static/fonts/FedraSerifProBBook.woff2';
import FedraSerifProABookItalic2 from '../static/fonts/FedraSerifProABookItalic.woff2';
import FedraSerifProBMedium2 from '../static/fonts/FedraSerifProBMedium.woff2';
import FedraSerifProBNormalIta2 from '../static/fonts/FedraSerifProBNormalIta.woff2';
import cssVars from './cssVars';
import { normalize } from 'polished';

const GlobalStyle = createGlobalStyle`
    ${normalize()}

    @font-face {
        font-family: 'FedraSerif-Bold';
        font-style: normal;
        font-weight: 700;
        src: url(${FedraSerifProBBold2}), url(${FedraSerifProBBold});
    }

    @font-face {
        font-family: 'FedraSerif-Book';
        font-style: normal;
        font-weight: normal;
        src: url(${FedraSerifProBBook2}), url(${FedraSerifProBBook});
    }

    @font-face {
        font-family: 'FedraSerif-BookItalic';
        font-style: normal;
        font-weight: normal;
        src: url(${FedraSerifProABookItalic2}), url(${FedraSerifProABookItalic});
        font-style: italic;
    }

    @font-face {
        font-family: 'FedraSerif-Medium';
        font-style: normal;
        font-weight: 500;
        src: url(${FedraSerifProBMedium2}), url(${FedraSerifProBMedium});
    }

    @font-face {
        font-family: 'FedraSerif-NormalItalic';
        font-style: normal;
        font-weight: 600;
        src: url(${FedraSerifProBNormalIta2}), url(${FedraSerifProBNormalIta});
        font-style: italic;
    }

    html {
        box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    body {
        font-family: ${cssVars.fonts.body};
        font-size: ${cssVars.fontConfigs['base']['xs']}px;
        line-height: 1.5;
        background-color: ${cssVars.colors.white};
        color: ${cssVars.colors.gray};
    }

    img {
        max-width: 100%;
        height: auto;
    }

    button {
        background-color: transparent;
        border: 0;
        padding: 0;
        cursor: pointer;
    }

    a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
    }

    /* Single-direction margin declarations */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hgroup,
    ul,
    ol,
    dd,
    p,
    figure,
    blockquote,
    pre,
    table,
    fieldset,
    hr {
        margin: 0;
    }
`;

export default GlobalStyle;
