import styled from 'styled-components';
import cssVars from '../../../styles/cssVars';
import { distance, fontSize } from '../../../styles/functions/cssRulesPerBreakpointPresets';
import { Link } from "react-router-dom";

const StyledArtObject = styled(Link)`
    display: flex;
    margin-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${cssVars.colors.grayLight};

    &:hover {
        text-decoration: none;
    }

    &:last-child {
        border-bottom: none;
    }

    .imgWrapper {
        width: 108px;
        height: 108px;

        img {
            object-fit: cover;
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .content {
        margin-left: 15px;
        margin-top: 5px;
    }

    .number {
        ${fontSize('small')};
        ${distance('padding-bottom', 'extraSmall')};
        font-family: 'Muli';
        font-weight: 600;
        color: ${cssVars.colors.gold};

        svg {
            fill: ${cssVars.colors.gold};
            margin-right: 7px;
        }
    }

    .name {
        ${fontSize('base')};
        ${distance('padding-bottom', 'extraSmall')};
        color: ${cssVars.colors.blueDark};
        font-family: 'FedraSerif-Bold';

    }

    .location {
        ${fontSize('extraSmall')};
        font-family: 'Muli';
        font-weight: 500;
        text-transform: uppercase;
        color: ${cssVars.colors.gray};
        letter-spacing: 1px;
    }
`;

export default StyledArtObject;
