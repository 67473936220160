import React from "react";
import StyledButton from "./Button.styles";
import PropTypes from "prop-types";
import cssVars from "../../../styles/cssVars";
import { Link } from "react-router-dom";

const Button = ({ text, bgColor, url }) => {
    return (
        <StyledButton bgColor={bgColor}>
            <Link to={url}>{text}</Link>
        </StyledButton>
    );
};

Button.propTypes = {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    bgColor: PropTypes.string
};

Button.defaultProps = {
    bgColor: cssVars.colors.blueLight
};

export default Button;
