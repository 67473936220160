import React from "react";
import PropTypes from "prop-types";
import StyledArtObject from "./ArtObject.styles";
import { ReactComponent as AudioIcon } from "../../../static/svg/audio.svg";
import { useParams } from "react-router-dom";

const ArtObject = ({ image, identifier, title, subtitle }) => {
    let { lang } = useParams();

    return (
        <StyledArtObject to={`/${lang}/locations/${identifier}`}>
            <div className="imgWrapper">
                <img src={image.overview_mobile} alt={image.alt} />
            </div>
            <div className="content">
                <p className="number">
                    <AudioIcon />
                    {identifier}
                </p>
                <p className="name">{title}</p>
                <p className="location">{subtitle}</p>
            </div>
        </StyledArtObject>
    );
};

ArtObject.propTypes = {
    title: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    image: PropTypes.shape({
        alt: PropTypes.string,
        overview_mobile: PropTypes.string,
        overview_tablet: PropTypes.string
    }).isRequired,
    subtitle: PropTypes.string.isRequired
};

export default ArtObject;
