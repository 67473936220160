const cssVars = {};

cssVars.borderRadius = {
    sm: '4px',
    md: '8px',
    lg: '16px',
};

cssVars.fonts = {
    body: "'FedraSerif-Book', Arial, Helvetica, sans-serif",
    head: 'FedraSerif-Bold',
};

cssVars.zIndexes = {
    negative: -1,
    default: 1,
}

cssVars.colors = {
    black: '#000',
    white: '#fff',
    blueDarker: '#395774',
    blueDark: '#002646',
    blueLight: '#8fb8c9',
    blueLighter: '#e5f1f6',
    gold: '#bf9B5d',
    gray: '#b8b8b8',
    grayLight: '#ededed',
    grayDark: '#585858',
    red: '#c70909'
};

cssVars.breakpointsArray = [0, 576, 768, 980, 1200, 1440];

cssVars.breakpoints = {
    xs: cssVars.breakpointsArray[0], // 0
    sm: cssVars.breakpointsArray[1], // 576
    md: cssVars.breakpointsArray[2], // 768
    lg: cssVars.breakpointsArray[3], // 980
    xl: cssVars.breakpointsArray[4], // 1200
    xxl: cssVars.breakpointsArray[5], // 1440
};

cssVars.grid = {
    gutter: 30,
    width: {
        sm: cssVars.breakpoints.sm - 30,
        md: cssVars.breakpoints.md - 30,
        lg: cssVars.breakpoints.lg - 30,
        xl: cssVars.breakpoints.xl - 30,
        xxl: cssVars.breakpoints.xxl - 30,
    },
};

cssVars.columnConfigs = {
    innerContent: { xs: 1, md: 10 / 12, lg: 2 / 6 },
};

cssVars.dist = {
    xxs: 5,
    xs: 10,
    sm: 15,
    md: 20,
    lg: 30,
    xl: 40,
    xxl: 60,
    xxxl: 90,
};

cssVars.distanceConfigs = {
    extraSmall: {
        xs: cssVars.dist.xxs,
        sm: cssVars.dist.xs,
    },
    small: {
        xs: cssVars.dist.sm,
    },
    medium: {
        xs: cssVars.dist.md,
        lg: cssVars.dist.lg,
    },
    large: {
        xs: cssVars.dist.lg,
        lg: cssVars.dist.xl,
    },
    extraLarge: {
        xs: cssVars.dist.xl,
        lg: cssVars.dist.xxl,
    },
    extraExtraLarge: {
        xs: cssVars.dist.xxl,
        lg: cssVars.dist.xxxl,
    },
    cardPadding: {
        xs: cssVars.dist.md,
    },
    callToActionMediumPadding: {
        xs: cssVars.dist.md,
        md: cssVars.dist.xxl,
        lg: cssVars.dist.xxxl,
    },
};

cssVars.fontConfigs = {
    extraSmall: {
        xs: 12,
    },

    small: {
        xs: 14,
    },

    base: {
        xs: 15,
    },

    medium: {
        xs: 16,
    },

    large: {
        xs: 18,
    },

    h1: {
        xs: 40,
    },

    h2: {
        xs: 21,
    },

    h3: {
        xs: 19,
    },
};

export default cssVars;
