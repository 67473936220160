import React from 'react';
import PropTypes from "prop-types";
import StyledNextObject from './NextObject.styles';
import Subtitle from '../../atoms/Subtitle/Subtitle.jsx';
import ArtObject from '../../atoms/ArtObject/ArtObject.jsx';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const NextObject = ({ item }) => {
    let { lang } = useParams();
    const { jsonData } = useSelector(state => state.dataReducer);

    return (
        <StyledNextObject>
            <Subtitle text={jsonData[lang].site.copy.next_object} />
            <ArtObject {...item} />
        </StyledNextObject>
    );
};

NextObject.propTypes = {
    item: PropTypes.shape(ArtObject.PropTypes).isRequired,
};

export default NextObject;
