import styled from 'styled-components';
import cssVars from '../../../styles/cssVars';
import Box from '../../styled/Box';

const StyledHeader = styled.div`
    background-color: ${cssVars.colors.blueDark};
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;

    ${Box} {
        justify-content: space-between;
    }
`;

export default StyledHeader;
