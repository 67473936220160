import styled from 'styled-components';

const StyledSocialsBar = styled.div`
    margin-bottom: 10px;

    a {
        margin-right: 17px;

        svg {
            width: 41px;
            heigth: 41px;
        }
    }
`;

export default StyledSocialsBar;
