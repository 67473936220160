import React from "react";
import PropTypes from "prop-types";
import StyledTourStart from "./TourStart.styles";
import Button from "../../atoms/Button/Button.jsx";
import Logo from "../../atoms/Logo/Logo.jsx";
import cssVars from "../../../styles/cssVars";

const TourStart = ({ hideButtons }) => (
    <StyledTourStart>
        <div className="inner">
            <div className="logo-wrapper">
                {!hideButtons && <p className="tour">Audio Tour</p>}
                <Logo className="logo" />
            </div>

            {!hideButtons && (
                <div className="description">
                    <p className="description-text">
                        Select a language to continue
                    </p>

                    <div className="buttons-wrapper">
                        <Button
                            text="Nederlands"
                            bgColor={cssVars.colors.gold}
                            url="/nl/intro"
                        />
                        <Button text="English" url="/en/intro" />
                    </div>
                </div>
            )}
        </div>
    </StyledTourStart>
);
TourStart.propTypes = {
    hideButtons: PropTypes.bool
};

TourStart.defaultProps = {
    hideButtons: false
};

export default TourStart;
