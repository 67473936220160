import { css } from 'styled-components';
import cssVars from '../cssVars';

const bpMax = Object.keys(cssVars.breakpoints).reduce((acc, label) => {
    acc[label] = (...args) =>
        css`
            @media (max-width: ${cssVars.breakpoints[label]}px) {
                ${css(...args)};
            }
        `;
    return acc;
}, {});

export default bpMax;
