import Header from "../components/organisms/Header/Header";
import LocationsList from "../components/organisms/LocationsList/LocationsList";
import Footer from "../components/organisms/Footer/Footer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TourStart from "../components/organisms/TourStart/TourStart";
import Overlay from "../components/styled/Overlay";
import locationListTransform from "../api/transformations/locationListTransform";
import PageHeader from "../components/organisms/PageHeader/PageHeader";
import Container from "../components/styled/Container";
import Box from "../components/styled/Box";
import Meta from "../components/molecules/Meta/Meta";
import Description from "../components/molecules/Description/Description";
import NextObject from "../components/molecules/NextObject/NextObject";
import AudioPlayer from "../components/molecules/AudioPlayer/AudioPlayer";
import Newsletter from "../components/molecules/Newsletter/Newsletter";
import FollowUs from "../components/molecules/FollowUs/FollowUs";
import { useSelector } from "react-redux";

const Locations = () => {
    let { lang, id } = useParams();
    const [data, setData] = useState(null);
    const [filteredLocations, setFilteredLocations] = useState(null);
    const [numberSearch, setNumberSearch] = useState(null);
    const [activeArtwork, setActiveArtwork] = useState(null);
    const [nextArtwork, setNextArtwork] = useState(null);
    const { jsonData } = useSelector(state => state.dataReducer);

    useEffect(() => {
        if (jsonData && jsonData[lang]) {
            setData({
                ...jsonData[lang],
                artworks: locationListTransform(jsonData[lang].artworks)
            });
        } else {
            setData(null);
        }
    }, [jsonData, lang]);

    useEffect(() => {
        if (id && data && data.artworks) {
            let selectedArtwork = null;
            data.artworks.forEach((location, locationIndex) => {
                if (selectedArtwork || location.items.length === 0) return;
                const selectedArtworkIndex = location.items.findIndex(
                    item => item.identifier && item.identifier === id
                );
                if (selectedArtworkIndex !== null) {
                    // Set the active artwork
                    selectedArtwork = location.items[selectedArtworkIndex];
                    // Now find the next artwork based on either next  one in current location
                    // or first one of next location
                    if (selectedArtworkIndex < location.items.length - 1) {
                        setNextArtwork(
                            location.items[selectedArtworkIndex + 1]
                        );
                    } else if (locationIndex < data.artworks.length - 1) {
                        if (data.artworks[locationIndex + 1].items.length > 0) {
                            setNextArtwork(
                                data.artworks[locationIndex + 1].items[0]
                            );
                        } else {
                            setNextArtwork(null);
                        }
                    } else {
                        setNextArtwork(null);   
                    }
                } else {
                    setNextArtwork(null);
                    
                }
            });
            setActiveArtwork(selectedArtwork);
        } else {
            setActiveArtwork(null);
        }
    }, [data, id]);

    useEffect(() => {
        if (data && data.artworks) {
            if (!numberSearch) {
                setFilteredLocations(data.artworks);
            } else {
                setFilteredLocations(
                    data.artworks.map(location => ({
                        ...location,
                        items: location.items.filter(
                            item =>
                                item.identifier &&
                                item.identifier.includes(`${numberSearch}`)
                        )
                    }))
                );
            }
        }
    }, [numberSearch, data]);

    return (
        <>
            <main>
                <Header
                    searchForNumber={num => {
                        setNumberSearch(num);
                    }}
                />
                {filteredLocations ? (
                    <LocationsList lang={lang} locations={filteredLocations} />
                ) : (
                    <TourStart hideButtons />
                )}
                {activeArtwork && (
                    <Overlay>
                        <PageHeader image={activeArtwork.image} />

                        <Container>
                            <Box>
                                <Meta
                                    title={activeArtwork.title}
                                    author={activeArtwork.subtitle}
                                    id={activeArtwork.identifier}
                                    year={activeArtwork.year}
                                />

                                <AudioPlayer audioSrc={activeArtwork.audio}/>

                                <Description text={activeArtwork.description} />

                                {nextArtwork ? (
                                  <NextObject item={nextArtwork} />
                                ) : (
                                  <>
                                    <Newsletter />
                                    <FollowUs />
                                  </>
                                )}
                            </Box>
                        </Container>
                    </Overlay>
                )}
                <Footer />
            </main>
        </>
    );
};

export default Locations;
