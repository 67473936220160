import styled from 'styled-components';
import cssVars from '../../../styles/cssVars';
import { distance, fontSize } from '../../../styles/functions/cssRulesPerBreakpointPresets';

const StyledMeta = styled.div`
    ${distance('padding-bottom', 'medium')};
    width: 100%;

    .title {
        ${fontSize('h2')};
        color: ${cssVars.colors.blueDark};
        font-family: 'FedraSerif-Medium';
    }

    .year {
        ${fontSize('base')};
        font-family: 'Muli';
        font-weight: 500;
    }

    .content-wrapper {
        display: flex;
        align-items: center;
    }

    .id {
        margin-right: 10px;

        svg {
            fill: ${cssVars.colors.gold};
            margin-right: 5px;
        }

        &-text {
            ${fontSize('small')};
            color: ${cssVars.colors.gold};
            font-family: 'Muli';
            font-weight: 500;
        }
    }

    .author {
        ${fontSize('small')};
        font-family: 'Muli';
        font-weight: 500;
        text-transform: uppercase;
    }
`;

export default StyledMeta;
