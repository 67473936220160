import styled from 'styled-components';
import cssVars from '../../../styles/cssVars';
import { fontSize } from '../../../styles/functions/cssRulesPerBreakpointPresets';

const StyledLocation = styled.div`
    background-color: white;

    .inner {
        width: 100%;
    }

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${cssVars.colors.grayLight};
        margin-top: 10px;
    }

    .head__location {
        ${fontSize('h2')};
        font-family: 'FedraSerif-Bold';
        color: ${cssVars.colors.gold};
    }

    .head__artworks {
        ${fontSize('small')};
        font-family: 'Muli';
        font-weight: 500;
        color: ${cssVars.colors.gold};
    }
`;

export default StyledLocation;
