import PropTypes from 'prop-types';
import React from 'react';
import Box from './Box';
import Container from './Container';

const InnerContentWrapper = ({ children, narrow }) => (
    <Container bottom="extraLarge" justifyContent="center">
        <Box column={narrow ? { xs: 1, md: 3 / 5, lg: 1 / 2 } : 'innerContent'} direction="row" alignItems="flex-start">
            {children}
        </Box>
    </Container>
);

InnerContentWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    narrow: PropTypes.bool,
};

InnerContentWrapper.defaultProps = {
    narrow: false,
};

export default InnerContentWrapper;
