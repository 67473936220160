import React from 'react';
import PropTypes from "prop-types";
import StyledSubtitle from './Subtitle.styles';

const Subtitle = ({ text }) => (
    <StyledSubtitle>
        {text}
    </StyledSubtitle>
);

Subtitle.propTypes = {
    text: PropTypes.string.isRequired,
};

Subtitle.defaultProps = {
    stringExample: 'Next Object',
};

export default Subtitle;
