import React from 'react';
import StyledNoResults from './NoResults.styles';
import Container from '../../styled/Container';
import Box from '../../styled/Box';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const NoResults = props => {
    let { lang } = useParams();
    const { jsonData } = useSelector(state => state.dataReducer);

    return (
        <StyledNoResults>
            <Container>
                <Box>
                    <p>{jsonData[lang].site.copy.no_results}</p>
                </Box>
            </Container>
        </StyledNoResults>
    );
};

export default NoResults;
