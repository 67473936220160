import React from 'react';
import StyledNewsletter from './Newsletter.styles';
import Subtitle from '../../atoms/Subtitle/Subtitle.jsx';
import NewsletterForm from '../../molecules/NewsletterForm/NewsletterForm.jsx';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Newsletter = props => {
    let { lang } = useParams();
    const { jsonData } = useSelector(state => state.dataReducer);

    return (
        <StyledNewsletter>
            <Subtitle text={jsonData[lang].site.copy.subscribe} />
            <NewsletterForm />
        </StyledNewsletter>
    );
};

export default Newsletter;
