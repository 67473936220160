import styled from "styled-components/macro";
import cssVars from "../../../styles/cssVars";
import { fontSize } from "../../../styles/functions/cssRulesPerBreakpointPresets";

const StyledButton = styled.div`
    a {
        ${fontSize("small")};
        position: relative;
        color: ${cssVars.colors.white};
        padding: 20px;
        display: inline-block;
        font-family: "Muli";
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        background-color: ${props => props.bgColor};
        text-align: center;
        width: 100%;

        &:hover {
            text-decoration: none;
        }
    }
`;

export default StyledButton;
