import styled from 'styled-components';
import cssVars from '../../../styles/cssVars';
import { distance } from '../../../styles/functions/cssRulesPerBreakpointPresets';

const StyledDescription = styled.div`
    ${distance('padding-bottom', 'medium')};
    width: 100%;
    font-family: 'FedraSerif-Book';

    .title {
        color: ${cssVars.colors.blueDark};
        font-family: 'FedraSerif-Medium';
    }

    .text {
        color: ${cssVars.colors.grayDark};
        font-family: 'FedraSerif-Book';
    }
`;

export default StyledDescription;
