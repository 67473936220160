import React from 'react';
import StyledFooter from './Footer.styles';
import Container from '../../styled/Container';
import Box from '../../styled/Box';
import SocialsBar from '../../molecules/SocialsBar/SocialsBar';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = props => {
    let { lang } = useParams();
    const { jsonData } = useSelector(state => state.dataReducer);

    return (
        <StyledFooter>
            <Container>
                <Box>
                    <SocialsBar />
                </Box>
                <Box>
                    <>
                        <a className="link" href={jsonData[lang].site.copy.terms_url} target="_blank" rel="noreferrer noopener">
                            { jsonData[lang].site.copy.terms_text }
                        </a>
                        <span>|</span>
                        <a className="link" href={jsonData[lang].site.copy.policy_url} target="_blank" rel="noreferrer noopener">
                            { jsonData[lang].site.copy.policy_text }
                        </a>
                        <span>|</span>
                        <a className="link" href={jsonData[lang].site.copy.contact_url} target="_blank" rel="noreferrer noopener">
                            { jsonData[lang].site.copy.contact_text }
                        </a>
                    </>
                </Box>
            </Container>
        </StyledFooter>
    )
};

export default Footer;
