import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import StyledAudioPlayer from './AudioPlayer.styles';
import { ReactComponent as PlayIcon } from '../../../static/svg/play.svg';
import { ReactComponent as PauseIcon } from '../../../static/svg/pause.svg';

const useAudio = url => {
  const [playing, setPlaying] = useState(false);
  const [audioRef, setAudioRef] = useState(null);
  const toggle = () => {
    console.log(url);
    if (audioRef) {
        if (audioRef.src !== url) {
            audioRef.src = url;
        }

        if (!playing) {
            audioRef.play();
        } else {
            audioRef.pause();
        }
    }

        setPlaying(!playing);
    };

    useEffect(() => {
        if (audioRef) {
            audioRef.src = url;
            audioRef.load();
        }
    }, [audioRef, url]);

  return [playing, toggle, audioRef, setAudioRef];
};

const AudioPlayer = ({ audioSrc }) => {
    const [playing, toggle, audioRef, setAudioRef] = useAudio(audioSrc);
    const [audioProgress, setAudioProgress] = useState('0:00');
    const [audioDuration, setAudioDuration] = useState('-');
    const [progBarValue, setProgBarValue] = useState(0);
    const progBarRef = useRef(null);

    const setProgress = () => {
        if (audioRef.duration) {
            setProgBarValue(audioRef.currentTime / audioRef.duration);
            setAudioDuration(formatTime(audioRef.duration));
        }

        if (audioRef.currentTime > 0) {
            setAudioProgress(formatTime(audioRef.currentTime));
        }
    }

    const formatTime = (time) => {
        if (time) {
            const minutes = parseInt(time / 60, 10);
            let seconds = parseInt(time % 60);
            seconds = ("0" + seconds).slice(-2);

            return `${minutes}:${seconds}`;
        } else {
            return '';
        }
    };

    return (
        <StyledAudioPlayer>
            <button type="button" className="controls" onClick={toggle}>
                { playing ? <PauseIcon /> : <PlayIcon /> }
            </button>

            <div className="playback">
                <div className="time-wrapper">
                    <span className="time time--current">
                        { audioProgress }
                    </span>
                    <span className="time time--length">{audioDuration}</span>
                </div>
                <input
                    ref={progBarRef}
                    className="progress"
                    type="range"
                    min="0"
                    max="1"
                    onInput={(e) => console.log('onInput', e)}
                    onChange={(e) => console.log('onChange', e)}
                    value={progBarValue}
                    step="0.01"
                />
                <audio
                    onTimeUpdate={() => setProgress()}
                    ref={c => setAudioRef(c)}>
                </audio>
            </div>
        </StyledAudioPlayer>
    );
};

AudioPlayer.propTypes = {
    audioSrc: PropTypes.string.isRequired,
};

AudioPlayer.defaultProps = {
    audioSrc: 'https://s3-eu-central-1.amazonaws.com/embassy-audio-dev/2020-03/SampleAudio_0.4mb.mp3',
};

export default AudioPlayer;
