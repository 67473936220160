import React from 'react';
import PropTypes from "prop-types";
import StyledPageHeader from './PageHeader.styles';
import BackButton from '../../atoms/BackButton/BackButton';

const PageHeader = ({ image }) => (
    <StyledPageHeader>
        <BackButton />
        <img src={image.detail_tablet} alt={image.alt} />
    </StyledPageHeader>
);

PageHeader.propTypes = {
    image: PropTypes.shape({
        detail_mobile: PropTypes.string,
        detail_tablet: PropTypes.string,
        alt: PropTypes.string,
    }).isRequired,
};

PageHeader.defaultProps = {
    image: {
        alt: 'Omschrijving van kunstwerk',
        detail_mobile: 'https://storage.boijmans.nl/styles/list/uploads/2019/09/09/peAtdowzDZRnBtoBiwkshpqORySzIPwN0j6RU6Xl.jpeg',
        detail_tablet: 'https://storage.boijmans.nl/styles/list/uploads/2019/09/09/peAtdowzDZRnBtoBiwkshpqORySzIPwN0j6RU6Xl.jpeg',
    },
};

export default PageHeader;
