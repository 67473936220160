import React from "react";
import PropTypes from "prop-types";
import StyledLocationsList from "./LocationsList.styles";
import Location from "../../molecules/Location/Location";
import NoResults from "../../molecules/NoResults/NoResults";

const  LocationsList = ({ locations }) => (
    <StyledLocationsList>
        {locations.length > 0 ? (
            locations.map(location => <Location key={location.location} {...location} />)
        ) : (
            <NoResults />
        )}
    </StyledLocationsList>
);

LocationsList.propTypes = {
    locations: PropTypes.arrayOf(PropTypes.shape(Location.PropTypes)),
};

LocationsList.defaultProps = {
    locations: []
};

export default LocationsList;
